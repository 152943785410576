function find( select ) {
  let selector = select;
  let counter = 0;
  const selectorMatch = /(.*):eq\((\d+)\)/.exec( selector );
  if ( selectorMatch !== null ) {
    selector = selectorMatch[1];
    counter = parseInt( selectorMatch[2], 10 );
  }

  let target = document.querySelectorAll( selector );
  if ( target.length > counter ) {
    return [target[counter]];
  }
  return [];
}

function insertBefore( selector, text ) {
  const elems = find( selector );
  elems.forEach( elem => elem.insertAdjacentHTML( 'beforebegin', text ) );
}

function prepend( selector, text ) {
  const elems = find( selector );
  elems.forEach( elem => elem.insertAdjacentHTML( 'afterbegin', text ) );
}

function append( selector, text ) {
  const elems = find( selector );
  elems.forEach( elem => elem.insertAdjacentHTML( 'beforeend', text ) );
}

function insertAfter( selector, text ) {
  const elems = find( selector );
  elems.forEach( elem => elem.insertAdjacentHTML( 'afterend', text ) );
}

export {append, find, insertAfter, insertBefore, prepend};
