export default class dataLayer {
  constructor( data ) {
    this.data = data;
  }

  /**
   * @param key
   * @returns {null|string}
   */
  getKey( key ) {
    for ( let obj of this.data ) {
      if ( Object.prototype.hasOwnProperty.call(obj, key ) ) {
        return obj[key];
      }
    }
    return null;
  }

  static getFirstElementOfArray( arry ) {
    if ( Array.isArray( arry ) ) {
      return arry[0];
    }

    return null;
  }
}
