import {default as adObject} from './config';
import Adlib from './../lib/adlib_3.0';
// import './../lib/lazy_queue';
import DataLayer from './../lib/dataLayer';
import lazyAdsArtikel from './scripts/lazyAdsArtikel';
import lazyAdsInstagram from './scripts/lazyAdsInstagram';
import lazyAdsGalerie from './scripts/lazyAdsGalerie';
import lazyAdsSponsoredGalerie from './scripts/lazyAdsSponsoredGalerie';
import lazyAdsHome from './scripts/lazyAdsHome';
import lazyAdsIndex from './scripts/lazyAdsIndex';

// import adRefreshAll from './plugins/adRefreshAll';
// import adRefreshGallery from './plugins/adRefreshGallery';
require('./scss/ads.scss');

window.__tcfapi('addEventListener', 2, function( tcdata, success ) {
  if ( success && tcdata.eventStatus === 'tcloaded' || tcdata.eventStatus === 'useractioncomplete' ) {
    console.log( ' adLib - utopia_de' );
    window.adlib = new Adlib( adObject, tcdata );
    (
      function() {
        function loaded() {
          const dataLayer = new DataLayer( window.dataLayer || [] );
          const bart = dataLayer.getKey( 'bart' );
          const pagePostType = dataLayer.getKey( 'pagePostType' );
          if ( pagePostType === 'frontpage' ) {
            lazyAdsHome( window );
            return true;
          }
          if ( bart === 'artikel' ) {
            const pagePostID = dataLayer.getKey( 'pagePostID' ) || '';
            if ( pagePostID === 'post_223565') {
              lazyAdsInstagram( window );
            } else {
              lazyAdsArtikel( window, pagePostType );
            }
            return true;
          }
          if ( bart === 'liste' ) {
            lazyAdsIndex( window );
            return true;
          }
          if ( bart === 'galerie' ) {
            if ( pagePostType === 'ut_sponsored_gallery' ) {
              lazyAdsSponsoredGalerie( window );
            } else {
              lazyAdsGalerie( window );
            }
            return true;
          }
          return false;
        }

        if ( document.readyState === 'interactive' || document.readyState === 'complete' ) {
          window.console.log('### readyState ###');
          loaded();
        } else {
          window.console.log('### DOMContentLoaded ###');
          document.addEventListener( 'DOMContentLoaded', loaded );
        }
      }
    )();

    if (tcdata.eventStatus === 'tcloaded') {
      // remove event listener when consent string has loaded
      window.__tcfapi('removeEventListener', 2, ( success ) => {
        console.log('removed event listener: ' + tcdata.listenerId);
      }, tcdata.listenerId);
    }
  }
} );
