import {insertAfter, insertBefore} from '../../lib/scripts/utilities';

export default function( window ) {
  if ( window.ads ) {
    let adQueue = window.adQueue || [];
    let mobilePos3Selector = '.article-list .article-list li:eq(3)';

    if ( window.location.href.indexOf('/page/') > -1 ) {
      //  console.log( ' ### home enthält /page/ ### ' );
      mobilePos3Selector =  '.article-list li:eq(3)';
    } else {
      console.log( ' ### home ### ' );
      window.adlib.getLog( ' stickyAd oben ', 'blue' );
      insertBefore( '.article-list .article-list li:eq(2)', '<li id="nativead_2" class="col-6 d-flex nativead_2" style="display:none;"></li>');
      adQueue.push( {type: 'adCall', data: {adSlot: 'nativead_2'}} );

      let nodes = document.querySelectorAll('.article-list .article-list li');
      console.log(nodes.length);
      let lastNode = nodes.length - 1;
      let last = '.article-list .article-list li:eq(' + lastNode + ')';

      window.adlib.getLog( ' stickyAd unten ', 'blue' );
      insertAfter( last, '<li id="nativead_3" class="col-6 d-flex nativead_3" style="display:none;"></li>');
      adQueue.push( {type: 'adCall', data: {adSlot: 'nativead_3'}} );

      nodes = document.querySelectorAll('.article-list .article-list li');
      console.log(nodes);
    }

    if ( window.MobileMinWidth.matches === false ) {
      adQueue.push( {type: 'adCall', data: {adSlot: 'skyscraper'}} );
    } else {
      adQueue.push( {type: 'adCall', data: {adSlot: 'Mobile_Pos1'}} );

      window.adlib.getLog( ' Mobile_Pos3 ', 'blue' );
      insertAfter(  mobilePos3Selector, '<li class="col-12"><div id="Mobile_Pos3" class="Mobile_Pos3 mitte1 mew-center-ad m1" style="display:none;"></div></li>');
      window.adQueue.push( {type: 'adCall', data: {adSlot: 'Mobile_Pos3'}} );
    }

    window.adlib.getLog( ' homebox oben ', 'blue' );
    insertAfter( '.sidebar .widget_ut_widgets_menuteaser:eq(0)', '<div class="two-column50 lazyload" style="overflow:hidden;margin:0 0 30px;padding:0 15px 15px;background: #f9f9f9;box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);" id="native_ads"><p class="anzeige" style="text-align:left;">- Anzeige -</p> <div id="nativead_1" class="nativead_1"></div></div>' );
    adQueue.push( {type: 'adCall', data: {adSlot: 'nativead_1'}} );

    window.adlib.getLog( ' homebox unten ', 'blue' );
    insertAfter( '.sidebar .widget_ut_widgets_menuteaser:eq(1)', '<div class="two-column50 lazyload" style="overflow:hidden;margin:0 0 30px;padding:0 15px 15px;background: #f9f9f9;box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);" id="native_ads2"><p class="anzeige" style="text-align:left;position: relative;">- Anzeige -</p> <div id="nativead_4" class="nativead_4"></div></div>' );
    adQueue.push( {type: 'adCall', data: {adSlot: 'nativead_4'}} );
  } // ads
}
