// import {insertBefore} from '../../lib/scripts/utilities';

export default function( window ) {
  if ( window.ads ) {
    // let mylazyQueue = window.mylazyQueue || [];
    let adQueue = window.adQueue || [];
    if ( window.MobileMinWidth.matches === true ) {

      window.adlib.getLog( ' Mobile_Pos3 lazyload ', 'blue'  );

    } else {
      adQueue.push( {type: 'adCall', data: {adSlot: 'skyscraper'}} );

      window.adlib.getLog(' Content_1 lazyload ', 'blue' );
    }

  }
}
