import {insertBefore} from '../../lib/scripts/utilities';

export default function( window ) {
  if ( window.ads ) {
    let mylazyQueue = window.mylazyQueue || [];
    let adQueue = window.adQueue || [];
    if ( window.MobileMinWidth.matches === true ) {

      window.adlib.getLog( ' Mobile_Pos2 lazyload ', 'blue' );
      insertBefore( '.tags', '<div id="Mobile_Pos2" style="min-height: 290px;" class="mobileAd mitte1 mew-center-ad lazyload adDeclaration adDecoration"></div>' );
      mylazyQueue.push( {
        Mobile_Pos2: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Mobile_Pos2'}} );
        },
      } );
    } else {
      adQueue.push( {type: 'adCall', data: {adSlot: 'skyscraper'}} );

      window.adlib.getLog(' Content_1 lazyload ', 'blue' );
      insertBefore( '.tags', '<div id="Content_1" class="contentAd mitte1 center-ad lazyload adDeclaration adDecoration"></div>' );
      mylazyQueue.push( {
        Content_1: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Content_1'}} );
        },
      } );

    }
  } // ads
}
